import React, { useEffect } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import PodcastGuestApplication from './components/PodcastGuestApplication';
import { trackPageView } from './utils/ga';

function App() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <main className="App max-w-[932px] pb-8 pt-16 px-10">
      <nav className="navbar">
        <ul className="nav-list">
          <li><Link to="/" className="nav-link">Home</Link></li>
          <li><Link to="/guest-application" className="nav-link">Be our Guest</Link></li>
        </ul>
      </nav>
      <Routes>
        <Route path="/guest-application" element={<PodcastGuestApplication />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
     </main>
  );
}

export default App;
