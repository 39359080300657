import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.scss';

const LandingPage = () => {
  return (
    <div className="container">
      <h1 className="page-title">Media Endeavor by Ndevr</h1>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/eGTloaUJ2QI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <h3 className="podcast-subtitle">Real stories. Actionable insights. For everyone in digital media publishing space.</h3>
      <p className="description">
        Media Endeavor dives into the real stories of those shaping the digital media publishing space. From editors creating content, developers making it all possible,  marketers and designers handling the audience experience, to leaders driving it all forward and shaping the future.
        We explore how they’ve built and scaled their platforms, navigated industry shifts, and adapted to an ever-changing digital landscape. Each episode dives into the intersection of content, technology, and operational strategy, offering actionable insights for media executives and digital publishers.
        </p>
      <p className="description">
        Whether you’re tackling scalability challenges, rethinking workflows, or striving to enhance audience engagement, Media Endeavor delivers strategies to help you thrive in today’s competitive media environment.
      </p>
      <p className="description">
        Join us on an exciting journey through the latest trends and innovations in technology. From AI to cybersecurity, we cover it all.
      </p>
      <div className="cta-container">
        <Link to="/guest-application" className="cta-button">
          Be our Guest
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
