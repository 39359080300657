const trackPageView = (page) => {
    window.gtag('event', 'page_view', {
      page_path: page,
    });
  };
  
  const trackEvent = (category, action, label) => {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  };
  
  export { trackPageView, trackEvent };
  