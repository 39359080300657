import React from 'react';
import Iframe from 'react-iframe';
import './PodcastGuestApplication.scss';
import cover from '../assets/MediEndeavorCover.png';

const PodcastGuestApplication = () => {

  return (
    <div className="container">
      <img className="cover" src={cover} alt="Cover" />
      <h1 className="page-title">Be a Guest on Our Podcast</h1>
      <p className="description">
      Do you have a story about scaling your platform, overcoming operational challenges, or driving innovation in media? We’d love to feature you on Media Endeavor. Share your insights with a global audience of media leaders and digital publishers.
      </p>
      <h3 className="steps-title">What We’re Looking For:</h3>
      <ol className="steps">
        <li>Stories about building scalable platforms or navigating industry shifts</li>
        <li>Insights into aligning content strategy with technology and operations</li>
        <li>Lessons learned from overcoming challenges in high-traffic environments</li>
      </ol>

      
      <h2 className="form-title">Schedule a Pre-interview Call</h2>
      <span className="subtitle">Let's find out if we are the right fit for each other.</span>
      <div className="calendar-container">
      <Iframe
        url="https://ndevr.setmore.com/services/94ee2a35-a950-4ce1-86c6-2ea253f09807?step=time-slot&products=94ee2a35-a950-4ce1-86c6-2ea253f09807&type=service&staff=d671a399-869f-4cf0-8c8c-fdd34a1d99c7&staffSelected=false"
        width="100%"
        height="600px"
        id="calendar-form"
        display="initial"
        position="relative"
      />
      </div>
    </div>
  );
};

export default PodcastGuestApplication;
